@font-face {
    font-family: 'Godo';
    font-style: normal;
    font-weight: 400;
    src: url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoM.woff2') format('woff2'), url('//cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoM.woff') format('woff');
}



table {
  border-collapse: collapse;
  margin: 0 auto;
  border-radius: 50px;
}

th, td {
  border: 1px solid #000;
  padding: 8px;
  text-align: left;
}



.nocenter{
  text-align: left;
}
.nowrap {
  white-space: nowrap;
}
body{
  font-family: 'Godo', sans-serif;
  
}
span{
  font-weight: bold;
  font-size: larger;
}

#logo{
  width: 50px;
  height: 50px;
  margin-bottom: -15px;
}
#app{
  text-align: center;
  
}
.nv{
  margin-bottom: 100px;
  color: gray;
  
}
#content{
  padding: 50px;
}

/* .New{
  padding-left: 100px;
  text-align: left;
} */
.gradient{
  background: -webkit-linear-gradient(#ff6600, #d400ff);
  -webkit-background-clip: border-box;
  -moz-background-clip: border-box;
  background-clip: border-box; 
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

button{
	width:120px;
	height: 40px;
	color:#fff;
	background: #004fff;
	font-size: 16px;
	border:none;
	border-radius: 20px;
	box-shadow: 0 4px 16px rgba(0,79,255,0.3);
	transition:0.3s;
	position: absolute;
	left:50%;
	/* top:50%; */
  padding-left: 43px;
	transform: translate(-50%,-50%);
}
/* button:focus {
	outline:0;
} */
button:hover{
	background: rgba(0,79,255,0.9);
	cursor: pointer;
	box-shadow: 0 2px 4px rgba(0,79,255,0.6);
}



/*input css*/

textarea,input{

  padding: 10px;
  box-sizing: border-box;
  border: solid 2px #1E90FF;
  border-radius: 5px;
  font-size: 16px;
  resize: both;
}

/* .New{
  padding-top: 10px;
  padding-left: 125px;
} */

th{
  background: linear-gradient(#65abf1, #f279fa);
}

/* #commentSend{
  margin-top: 10%;
} */
